import { GatsbyBrowser } from 'gatsby';
import Auth from '@aws-amplify/auth'

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ args, options }) => {
    Auth.currentAuthenticatedUser()
        .then(user => {
            const userInfo = {
                ...user.attributes,
                username: user.username
            };
        })
        .catch(err => {
            window.localStorage.setItem('gatsbyUser', "")
        })
};

